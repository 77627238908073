import { InsertEmoticon } from '@material-ui/icons';
import { Box, ButtonBase, ClickAwayListener, Popper } from '@material-ui/core';
import { init } from 'emoji-mart';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { MouseEvent, useState } from 'react';
import { ReactionModel, ReactorModel } from 'api/model/reaction';
import { customEmojiData } from './resources/customEmojiData';
import styled from 'styled-components';
import { UserModel } from 'api/model/user';
import LightTooltip from 'components/atoms/LightToolTip';

interface EmEmojiProps {
  id?: string;
  shortcodes?: string;
  native?: string;
  size?: string;
  fallback?: string;
  set?: 'native' | 'apple' | 'facebook' | 'google' | 'twitter';
  skin?: 1 | 2 | 3 | 4 | 5 | 6;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'em-emoji': EmEmojiProps;
    }
  }
}

export type Props = {
  reactions: ReactionModel[];
  user: UserModel;
  onAdded: (emojiCode: string) => void;
  onDeleted: (reactionId: number) => void;
};

const EmojiButton = styled(ButtonBase)<{ highlighted?: boolean }>(
  ({ highlighted }) => ({
    height: 36,
    borderRadius: 18,
    backgroundColor: '#F2F2F2',
    border: highlighted ? 'solid #ff0000 1.5px' : '',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'center',
    '& span': {
      paddingTop: '4px',
      paddingBottom: '4px',
      fontSize: 13,
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: highlighted ? '#ff0000' : '',
      verticalAlign: 'middle',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  })
);

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '0.5em',
  marginTop: '1em',
}));

const EmojiPOnTooltip = styled.p`
  margin-bottom: 8px;
  display: block;
  & span {
    line-height: 1;
  }
`;

const TooltipBody = styled(Box)(() => ({
  textAlign: 'center',
  padding: '8px',
}));

// 絵文字データの初期化
init({
  data,
  custom: customEmojiData,
});

export default function ReactionList(props: Props) {
  const { reactions, user, onAdded, onDeleted } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClickAdd = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleClickEmoji = (emojiCode: string) => {
    const reactionId = findCurrentAdminReaction(emojiCode);
    if (reactionId) {
      // ログインユーザーがすでに同じemojiCodeでリアクションを登録していた場合、そのリアクションを削除する
      onDeleted(reactionId);
    } else {
      onAdded(emojiCode);
    }
  };

  const findCurrentAdminReaction = (emojiCode: string): number | undefined => {
    return reactions
      .find((r) => r.code === emojiCode)
      ?.reactores.find(isCurrentAdmin)?.reaction_id;
  };

  const isCurrentAdmin = (reactor: ReactorModel): boolean => {
    return reactor.reactor_type === 'User' && reactor.reactor_id === user?.id;
  };

  return (
    <>
      <StyledBox>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>
            <EmojiButton onClick={handleClickAdd}>
              <InsertEmoticon fontSize="default" />
            </EmojiButton>
            <Popper open={open} anchorEl={anchorEl} placement="right-start">
              <Picker
                data={data}
                custom={customEmojiData}
                emojiSize={20}
                emojiButtonSize={30}
                perLine={8}
                previewPosition="none"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onEmojiSelect={(emoji: any) => {
                  setAnchorEl(null);
                  handleClickEmoji(emoji['shortcodes']);
                }}
              />
            </Popper>
          </Box>
        </ClickAwayListener>
        {reactions.map((reaction, index) => (
          <LightTooltip
            key={index}
            title={
              <TooltipBody>
                <EmojiPOnTooltip>
                  <span>
                    <em-emoji shortcodes={reaction.code} size="5em" />
                  </span>
                </EmojiPOnTooltip>
                <p>
                  {reaction.reactores.map((reactor) => reactor.name).join(', ')}
                </p>
              </TooltipBody>
            }
          >
            <Box>
              <EmojiButton
                key={index}
                onClick={() => handleClickEmoji(reaction.code)}
                highlighted={reaction.reactores.some(isCurrentAdmin)}
              >
                <em-emoji shortcodes={reaction.code} size="1.5em" />
                <span>&nbsp;{reaction.reactores.length}</span>
              </EmojiButton>
            </Box>
          </LightTooltip>
        ))}
      </StyledBox>
    </>
  );
}
