import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { useParams } from 'react-router-dom';
import {
  getArticle,
  toggleConfirmation,
  updateMaterialCompleted,
} from '../articleSlice';
import { fetchAppointments } from '../../appointment/appointmentSlice';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/browser';
import ArticleDetail from '../../../components/pages/ArticleDetail';
import { onSendArticleReview } from 'features/review/reviewSlice';
import { createMessage } from 'features/message/messageSlice';
import { useLocation } from 'react-router';
import { QuotableType } from 'api/message';

type Props = {
  children?: ReactNode;
};

export default function ArticleDetailWithRedux(props: Props) {
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // TODO: as any良くない
  const article = useSelector(
    (state: RootState) => state.article.article
  ) as any;
  const fetching = useSelector((state: RootState) => state.article.fetching);
  const construction = useSelector(
    (state: RootState) => state.user.user.login_construction
  );
  const agenda = useSelector((state: RootState) => state.agenda.agenda);
  const materialCompletedLoading = useSelector(
    (state: RootState) => state.article.materialCompletedLoading
  );

  useEffect(() => {
    if (location.hash === '#message') {
      localStorage.removeItem('agenda');
    } else if (agenda) {
      localStorage.setItem('agenda', JSON.stringify(agenda));
    }
  }, [agenda, location.hash]);

  useEffect(() => {
    async function f() {
      try {
        await dispatch(getArticle(Number(id)));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch, id]);

  const onToggle = (householdId: number | null) => {
    async function f() {
      try {
        await dispatch(toggleConfirmation(Number(id), householdId));
        await dispatch(fetchAppointments(true));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onComplete = () => {
    async function f() {
      try {
        await dispatch(updateMaterialCompleted(Number(id)));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onSubmitArticleReview = async (
    reviewTargetId: number,
    comment: string | null,
    constructionId: number
  ) => {
    try {
      await dispatch(
        onSendArticleReview(reviewTargetId, comment, constructionId)
      );
    } catch (error) {
      throw error;
    }
  };

  const onSubmitPostMessage = async (
    body: string,
    quotableId: number,
    quotableType: QuotableType
  ) => {
    try {
      await dispatch(
        createMessage(body, [], null, undefined, quotableId, quotableType)
      );
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <ArticleDetail
        article={article}
        fetching={fetching}
        materialCompletedLoading={materialCompletedLoading}
        handleToggleConfirmation={(householdId: number | null) =>
          onToggle(householdId)
        }
        handleCompletion={() => onComplete()}
        handedOver4monthsAgo={construction!.handed_over_4months_ago}
        construction={construction || undefined}
        onSubmitArticleReview={(
          reviewTargetId: number,
          comment: string | null,
          constructionId: number
        ) => onSubmitArticleReview(reviewTargetId, comment, constructionId)}
        onSubmitPostMessage={onSubmitPostMessage}
      />
    </>
  );
}
